import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, NavLink } from 'react-router-dom';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import MiddleEllipsis from "react-middle-ellipsis";

import { useAuthContext } from '../hooks/useAuthContext';

const Team = () => {

	const { loggedInUser } = useAuthContext();

	const queryParameters = new URLSearchParams(window.location.search);
	var referrerWallet = queryParameters.get("referral");

	const [copied, setCopied] = useState(false);
	const [copyText, setCopyText] = useState("");
	const copyReferral = () => {    
	  setCopied(true);
	  setCopyText("Copied");
	  setTimeout(() => setCopied(false), 3000);
	}

	const [userData, setUserData] = useState(null);
	const fetchUserData = async () => {
	  const response = await fetch('https://api.fg6capital.com/user/'+loggedInUser.user.username, {
		method: 'GET',
		headers: {
		  'Content-Type': 'application/json',
		  'Authorization': `Bearer ${loggedInUser.token}`
		}
	  });
	  const json = await response.json();
	  if (response.ok) {
		if (json.length === 1) {
		  // Expect only 1 result
		  console.log(json[0]);
		  setUserData(json[0]);
		} else {
		  console.log("Invalid user data: More than 1 result.");
		}
	  }
	};

	const [downlinesData, setDownlinesData] = useState(null);
	const fetchDownlinesData = async () => {
		const response = await fetch('https://api.fg6capital.com/user/downline/'+loggedInUser.user.username, {
			method: 'GET',
			headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${loggedInUser.token}`
			}
		});
		const json = await response.json();
		if (response.ok) {
			console.log(json);
			setDownlinesData(json);
		}
	};

	useEffect(() => {
		if (loggedInUser) {
			fetchUserData();
			fetchDownlinesData();
		}
	}, [loggedInUser]);

	useEffect(() => {
		console.log("Referrer Wallet from URL:", referrerWallet);
	}, []);

	return (
		<section className="section-preset">
			<div className="container">

				<div className="row">
					<div className="col-12 p-1">
						<NavLink to="/"><p className="brand-primary" style={{fontSize:"18px"}}><strong><i className="fa-solid fa-arrow-left mr-2"></i>Back</strong></p></NavLink>
						<h4 className="text-white"><strong>Referral Program</strong></h4>
						<p className="text-gray mb-0">Invite your friends to trade with us and earn up to 5% bonus from your team's trading profits.</p>
						<div className="data-box mt-3">
							<h6 className="text-white mb-0"><strong>My Referral Code</strong></h6>
							<p className="text-gray mb-0">https://fg6capital.com/register?referral={loggedInUser.user.username}</p>
							<CopyToClipboard 
								text={"https://fg6capital.com/register?referral="+loggedInUser.user.username}
								onCopy={() => copyReferral()}>
								<button className="btn action-button mt-2" onClick={(e) => {
								}}>
									{!copied ? (
										<p className="mb-0"><strong>Copy</strong></p>
									):(
										<p className="mb-0"><strong>{copyText}</strong></p>
									)}
								</button>
							</CopyToClipboard>
						</div>
						<div className="data-box mt-3">
							<h6 className="text-white mb-0">My Referrals</h6>
							<p className="yellow balance-text mb-0">{downlinesData && downlinesData.length ? downlinesData.length : 0}</p>
						</div>
						<div className="data-box mt-3">
							<h6 className="text-white mb-0">Team Total Volume</h6>
							<p className="profit-green balance-text mb-0">{parseFloat(0).toFixed(2)} <span className="text-gray" style={{fontSize:"16px"}}>USDT</span></p>
						</div>
					</div>
				</div>

				<div className="row mt-5">
					<div className="col-12 p-1">
						<h5 className="text-gray"><strong>My Team Members</strong></h5>
						{downlinesData && downlinesData.length > 0 ? (
							<>
								{downlinesData && downlinesData.map((teamUser, index) => (
									<div className="data-box mt-2" key={index}>
										<h6 className="yellow mb-0">{teamUser.username}</h6>
										<p className="text-gray mt-1 mb-0">Trade Volume: <span className="profit-green">{parseFloat(0).toFixed(2)}</span></p>
									</div>
								))}

							</>
						):(
							<div className="data-box mt-2">
								<p className="text-gray mb-0">No team member is found for this account.<br></br>Invite your friend to join FG6 and start trading now!</p>
							</div>
						)}
					</div>
				</div>

			</div>
		</section>
	);

};

export default Team;