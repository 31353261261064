import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, NavLink } from 'react-router-dom';

const Trade = () => {

	useEffect(() => {

	}, []);

	return (
		<section className="section-preset">
			<div className="container">
				<div className="row">

					<div className="col-12 p-1">
						<NavLink to="/"><p className="brand-primary" style={{fontSize:"18px"}}><strong><i className="fa-solid fa-arrow-left mr-2"></i>Back</strong></p></NavLink>
						<h4 className="text-gray"><strong>Trading Summary</strong></h4>
						<div className="data-box mt-3">
							<h6 className="text-gray mb-0">Total Accumulative Profit</h6>
							<p className="text-white balance-text mb-0">0.00 <span className="text-gray" style={{fontSize:"16px"}}>USDT</span></p>
						</div>
						<div className="data-box mt-3">
							<h6 className="text-gray mb-0">Unrealized PnL</h6>
							<p className="profit-green balance-text mb-0">0.00 <span className="text-gray" style={{fontSize:"16px"}}>USDT</span></p>
							{/* <p className="profit-green pnl-text mb-0">6.83%</p> */}
						</div>
					</div>


				</div>

				<div className="row mt-5">
					<div className="col-12 p-1">
						<h5 className="text-gray"><strong>Open Order</strong></h5>

						{/* <div className="data-box mt-3">
							<h6 className="text-gray mb-0"><strong>Order #00001</strong></h6>
							<p className="text-gray small-font mb-0" style={{fontSize:"12px"}}>Status: <span className="pending">Active</span></p>
							<hr className="divider mt-2"></hr>
							<div className="row">
								<div className="col-sm-6 mt-2">
									<p className="text-gray small-font mb-0" style={{fontSize:"12px"}}>Order Amount:</p>
									<p className="profit-green order-text mb-0">{parseFloat(50).toFixed(2)} USDT</p>
								</div>
								<div className="col-sm-6 mt-2">
									<p className="text-gray small-font mb-0" style={{fontSize:"12px"}}>Unrealized PnL:</p>
									<p className="profit-green order-text mb-0">0.00%</p>
								</div>
							</div>
						</div> */}

						{/* <div className="data-box mt-3">
							<h6 className="text-gray mb-0"><strong>Order #00008</strong></h6>
							<hr className="divider mt-2"></hr>
							<div className="row">
								<div className="col-sm-6 mt-2">
									<p className="text-gray small-font mb-0" style={{fontSize:"12px"}}>Order Amount:</p>
									<p className="profit-green order-text mb-0">{parseFloat(5000).toFixed(2)} USDT</p>
								</div>
								<div className="col-sm-6 mt-2">
									<p className="text-gray small-font mb-0" style={{fontSize:"12px"}}>Profit/Loss (%):</p>
									<p className="profit-green order-text mb-0">+ 36%</p>
								</div>
								<div className="col-sm-6 mt-2">
									<p className="text-gray small-font mb-0" style={{fontSize:"12px"}}>Buy In:</p>
									<p className="text-gray order-text mb-0">55,768.89</p>
								</div>
								<div className="col-sm-6 mt-2">
									<p className="text-gray small-font mb-0" style={{fontSize:"12px"}}>Stop Loss:</p>
									<p className="pending order-text mb-0">53,800.00</p>
								</div>
							</div>
						</div> */}

						<div className="data-box mt-3">
							<p className="text-gray mb-0">No active order is found for this account.</p>
						</div>

					</div>
				</div>

				<div className="row mt-5">
					<div className="col-12 p-1">
						<h5 className="text-gray"><strong>Order History</strong></h5>
						{/* <div className="data-box mt-3">
							<h6 className="text-gray mb-0"><strong>Order #</strong></h6>
							<p className="text-gray mb-0" style={{fontSize:"16px"}}><span className="error"><strong>Missed</strong></span></p>
							<hr className="divider mt-2"></hr>
							<div className="row">
								<div className="col-sm-6 mt-2">
									<p className="text-gray small-font mb-0" style={{fontSize:"12px"}}>Date & Time:</p>
									<p className="text-gray order-text mb-0">-</p>
								</div>
								<div className="col-sm-6 mt-2">
									<p className="text-gray small-font mb-0" style={{fontSize:"12px"}}>Realized PnL:</p>
									<p className="profit-green order-text mb-0">0.00%</p>
								</div>
							</div>
						</div> */}
						<div className="data-box mt-3">
							<p className="text-gray mb-0">No order history is found for this account.</p>
						</div>
					</div>
				</div>

			</div>
		</section>
	);

};

export default Trade;