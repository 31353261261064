// Dependencies
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";


import MainLogo from './assets/img/logo.png';

// Pages
import RegisterPage from './pages/register';
import LoginPage from './pages/login';
import BindingPage from './pages/binding';
import TradePage from './pages/trade';
import PremiumPage from './pages/premium';
import TeamPage from './pages/team';
import BonusPage from './pages/bonus';
import ProfilePage from './pages/profile';

// Hooks
import { useAuthContext } from './hooks/useAuthContext';


function App() {

  // Set state for blockchain data
  // const dispatch = useDispatch();
  // const blockchain = useSelector((state) => state.blockchain);

  const { loggedInUser } = useAuthContext();

  const [btcMarketPrice, setBTCMarketPrice] = useState(0);
  const [btcPercentage, setBTCPercentage] = useState(0);
  const fetchBTCMarketPrice = async () => {
    const response = await fetch('https://api.fg6capital.com/bybitapi/price/btc');
    const json = await response.json();
    if (response.ok) {
      console.log(json);
      setBTCMarketPrice(json.markPrice);
      setBTCPercentage(json.price24hPcnt);
    } else {
      console.log(json);
    }
	};

  const [userData, setUserData] = useState(null);
  const fetchUserData = async () => {
    const response = await fetch('https://api.fg6capital.com/user/'+loggedInUser.user.username, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${loggedInUser.token}`
      }
    });
    const json = await response.json();
    if (response.ok) {
      if (json.length === 1) {
        // Expect only 1 result
        console.log(json[0]);
        setUserData(json[0]);
      } else {
        console.log("Invalid user data: More than 1 result.");
      }
    }
	};

  const [userByBitBalance, setUserBybitBalance] = useState(null);
  const fetchUserBybitBalance = async () => {
    const response = await fetch('https://api.fg6capital.com/bybitapi/user/balance', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${loggedInUser.token}`
      }
    });
    const json = await response.json();
    if (response.ok) {
      console.log("User Unified Account Data:", json);
      console.log("User Total Unified Wallet Balance:", json.data.result.list[0].totalWalletBalance);
      setUserBybitBalance(json.data.result.list[0].totalWalletBalance);
    }
	};

  useEffect(() => {
    if (loggedInUser) {
      fetchUserData();
      fetchUserBybitBalance();
    }
  }, [loggedInUser]);

  useEffect(() => {
    fetchBTCMarketPrice();
    const intervalId = setInterval(fetchBTCMarketPrice, 10000);
    return () => clearInterval(intervalId);
  }, []);


  return (
    <div className="d-flex justify-content-center">
      <div className="mobile-view">
        <Router>

          <section className="header">
            <div className="container">
              <div className="row">
                <div className="col-7 p-1 d-flex justify-content-left">
                  {/* <h5 className="header-text text-gray"><i className="text-gray fa-solid fa-user mr-2"></i>Michael</h5> */}
                </div>
                <div className="col-5 p-1 text-right">
                  {!loggedInUser ? (
                    <NavLink to="/login">
                      <button className="btn p-0" onClick={(e) => {
                      }}>
                        <h5 className="header-text text-gray mb-0"><i className="text-gray fa-solid fa-user mr-2"></i>Login</h5>
                      </button>
                    </NavLink>
                  ):(
                    <h5 className="header-text text-gray mb-0"><i className="text-gray fa-solid fa-user mr-2"></i>{loggedInUser.user.username}</h5>
                  )}
                </div>
              </div>
            </div>
          </section>

          <Routes>
            <Route
              path="/"
              element={loggedInUser ?
                <section className="section-preset">
                  <div className="container">
                    <div className="row">

                      {/* Account Data Summary */}
                      <div className="col-12 p-1">

                        <div className="data-box">
                          <h6 className="text-gray mb-0">Bybit: BTCUSDT</h6>
                          <p className="text-white balance-text mb-0">{btcMarketPrice}</p>
                          {btcPercentage > 0 ? (
                            <p className="profit-green pnl-text mb-0">+{parseFloat(btcPercentage*100).toFixed(2)}%</p>
                          ):(
                            <p className="error pnl-text mb-0">{parseFloat(btcPercentage*100).toFixed(2)}%</p>
                          )}
                        </div>

                        <div className="data-box mt-2">
                          
                          {userData && !userData.apiActivated ? (
                            <>
                              <h6 className="text-white mb-0">Bybit Trading Wallet</h6>
                              <p className="text-gray mt-1 mb-0" style={{fontSize:"16px"}}>
                                Setup Bybit API Binding to track your trading account balance and start trading with FG6.
                              </p>
                              <NavLink to="/binding">
                                <button className="btn action-button mt-2">
                                  <p className="text-white mb-0" style={{fontSize:"14px"}}><strong>Bind API</strong></p>
                                </button>
                              </NavLink>
                            </>
                          ):(
                            <>
                              <h6 className="text-gray mb-0">Bybit Trading Wallet</h6>
                              <p className="text-white balance-text mb-0">{userByBitBalance && userByBitBalance ? parseFloat(userByBitBalance).toFixed(2) : parseFloat(0).toFixed(2)} <span className="text-gray" style={{fontSize:"16px"}}>USDT</span></p>
                            </>
                          )}
                        </div>

                        <div className="data-box mt-2">
                          <h6 className="text-gray mb-0">FG6 Wallet</h6>
                          <p className="text-white balance-text mb-0">{userData && parseFloat(userData.cashWallet).toFixed(2)} <span className="text-gray" style={{fontSize:"16px"}}>USDT</span></p>
                          <button disabled className="btn action-button mt-2" onClick={(e) => {
                          }}>
                            <p className="mb-0" style={{fontSize:"14px"}}><strong>Recharge</strong></p>
                          </button>
                        </div>

                      </div>
                      
                      {/* Menu Button */}
                      <div className="col-12">
                        <div className="row">
                          <div className="col-4 p-1">
                            <NavLink to="/binding">
                            <div className="data-box text-center">
                              <i className="menu-icon fa-solid fa-link"></i>
                              <p className="text-gray mt-2 mb-0" style={{fontSize:"12px"}}>Bind API</p>
                            </div>
                            </NavLink>
                          </div>
                          <div className="col-4 p-1">
                            <NavLink to="/trade">
                            <div className="data-box text-center">
                              <i className="menu-icon profit-green fa-solid fa-chart-mixed-up-circle-dollar"></i>
                              <p className="text-gray mt-2 mb-0" style={{fontSize:"12px"}}>Trading</p>
                            </div>
                            </NavLink>
                          </div>
                          <div className="col-4 p-1">
                            <NavLink to="/premium">
                            <div className="data-box text-center">
                              <i className="menu-icon vip fa-sharp fa-solid fa-crown"></i>
                              <p className="text-gray mt-2 mb-0" style={{fontSize:"12px"}}>Premium</p>
                            </div>
                            </NavLink>
                          </div>
                          <div className="col-4 p-1">
                            <NavLink to="/team">
                            <div className="data-box text-center">
                              <i className="brand-secondary menu-icon fa-solid fa-people-group"></i>
                              <p className="text-gray mt-2 mb-0" style={{fontSize:"12px"}}>Team</p>
                            </div>
                            </NavLink>
                          </div>
                          <div className="col-4 p-1">
                            <NavLink to="/bonus">
                            <div className="data-box text-center">
                              <i className="gift menu-icon fa-solid fa-gift"></i>
                              <p className="text-gray mt-2 mb-0" style={{fontSize:"12px"}}>Bonus</p>
                            </div>
                            </NavLink>
                          </div>
                          <div className="col-4 p-1">
                            <NavLink to="/profile">
                              <div className="data-box text-center">
                                <i className="menu-icon fa-solid fa-user"></i>
                                <p className="text-gray mt-2 mb-0" style={{fontSize:"12px"}}>Profile</p>
                              </div>
                            </NavLink>
                          </div>
                        </div>
                      </div>

                      {/* <div className="col-12 mt-5 p-1">
                        <div className="data-box mt-3">
                          <h6 className="text-gray mb-0">Total Accumulative Profit (USDT)</h6>
                          <p className="text-white balance-text mb-0">$4568.93</p>
                          <p className="profit-green pnl-text mb-0">+50.32% (24h)</p>
                        </div>
                      </div> */}

                    </div>
                  </div>
                </section>
              :
              <Navigate to="/login" />
            }/>
            <Route path="/login" element={!loggedInUser ? <LoginPage/> : <Navigate to="/" />}/>
            <Route path="/register" element={!loggedInUser ? <RegisterPage/> : <Navigate to="/" />}/>
            <Route path="/binding" element={loggedInUser ? <BindingPage/> : <Navigate to="/login" />}/>
            <Route path="/trade" element={loggedInUser ? <TradePage/> : <Navigate to="/login" />}/>
            <Route path="/premium" element={loggedInUser ? <PremiumPage/> : <Navigate to="/login" />}/>
            <Route path="/team" element={loggedInUser ? <TeamPage/> : <Navigate to="/login" />}/>
            <Route path="/bonus" element={loggedInUser ? <BonusPage/> : <Navigate to="/login" />}/>
            <Route path="/profile" element={loggedInUser ? <ProfilePage/> : <Navigate to="/login" />}/>
          </Routes>
        </Router>

      </div>
    </div>
  );
}

export default App;