import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

// Hooks
import { useBindAPI } from '../hooks/useBindAPI';
import { useAuthContext } from '../hooks/useAuthContext';

const Binding = () => {

	const { loggedInUser } = useAuthContext();

	const { bindAPI, isLoading, error } = useBindAPI();

	const [apiKey, setApiKey] = useState('');
	const [apiSecret, setApiSecret] = useState('');

	const [userData, setUserData] = useState(null);
	const fetchUserData = async () => {
	  const response = await fetch('https://api.fg6capital.com/user/'+loggedInUser.user.username, {
		method: 'GET',
		headers: {
		  'Content-Type': 'application/json',
		  'Authorization': `Bearer ${loggedInUser.token}`
		}
	  });
	  const json = await response.json();
	  if (response.ok) {
		if (json.length === 1) {
		  // Expect only 1 result
		  console.log(json[0]);
		  setUserData(json[0]);
		} else {
		  console.log("Invalid user data: More than 1 result.");
		}
	  }
	};

	const handleSubmit = async (e) => {
        e.preventDefault();
		await bindAPI(loggedInUser.token, apiKey, apiSecret);
		fetchUserData();
    };

	useEffect(() => {
		if (loggedInUser) {
		  fetchUserData();
		}
	  }, [loggedInUser]);

	return (
		<section className="section-preset">
			<div className="container">
				<div className="row">
					<div className="col-12 p-1">
						<NavLink to="/"><p className="brand-primary" style={{fontSize:"18px"}}><strong><i className="fa-solid fa-arrow-left mr-2"></i>Back</strong></p></NavLink>
						<h4 className="text-gray"><strong>API Binding</strong></h4>
						<div className="data-box">
							{userData && !userData.apiActivated ? (
								<div className="row">
									<div className="col-12">
										<h6 className="text-white mb-0"><strong>ByBit API</strong></h6>
										<p className="text-white small-font mt-1 mb-0">Status:<span className="pending ml-1">Not binded</span></p>
									</div>
									<div className="col-12 mt-2">
										<form className="w-100" onSubmit={handleSubmit}>
											<div className="mb-2">
												<input
													type="text"
													placeholder='Enter API Key'
													autoComplete='off'
													name='apiKey'
													className='form-control'
													onChange={(e) => setApiKey(e.target.value)}
												/>
											</div>
											<div className="mb-2">
												<input
													type="text"
													placeholder='Enter API Secret'
													autoComplete='off'
													name='apiSecret'
													className='form-control'
													onChange={(e) => setApiSecret(e.target.value)}
												/>
											</div>
											{error ? <p className="error mb-0">{error}</p>: ""}
											<button disabled={apiKey == '' || apiSecret == ''} type="submit" className="btn action-button mt-2">
												<p className="text-white mb-0" style={{fontSize:"14px"}}><strong>Bind API</strong></p>
											</button>
										</form>
									</div>
								</div>
							):(
								<div className="row">
									<div className="col-sm-4">
										<h6 className="text-white mb-0"><strong>ByBit API</strong></h6>
										<p className="text-white small-font mt-1 mb-0">Status:<span className="profit-green ml-1">Active</span></p>
									</div>
									<div className="col-sm-8 mt-2 mt-sm-0">
										<p className="text-white small-font mb-0"><strong>API Key:</strong> {userData && userData.bybitApiKey ? userData.bybitApiKey : "Not Set"}</p>
										<p className="text-white small-font mb-0"><strong>API Secret:</strong> {userData && userData.bybitApiSecret ? "********" : "Not Set"}</p>
									</div>
								</div>
							)}	
						</div>
					</div>
				</div>
			</div>
		</section>
	);

};

export default Binding;