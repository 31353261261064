import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

const Premium = () => {

	useEffect(() => {

	}, []);

	return (
		<section className="section-preset">
			<div className="container">
				<div className="row">
					<div className="col-12 p-1">
						<NavLink to="/"><p className="brand-primary" style={{fontSize:"18px"}}><strong><i className="fa-solid fa-arrow-left mr-2"></i>Back</strong></p></NavLink>
						<h4 className="text-gray"><strong>FG6 Membership</strong></h4>
						<p className="text-gray">Subscribe to our membership to enjoy lower transaction fee and other benefits.</p>
					</div>

					<div className="col-12 p-1">
						<div className="data-box">
							<h5 className="text-gray mb-0">FG6 Member</h5>
							<p className="text-gray mb-0">FG6 trading bot is FREE forever!<br></br><span style={{textDecoration:"line-through"}}> 30% </span> 60% transaction fee from trading profits.</p>
							<p className="profit-green price-text mb-0">FREE</p>
						</div>
						<div className="data-box mt-3">
							<h5 className="text-gray mb-0">Premium Package</h5>
							<p className="text-gray mb-0">30% transaction fee from trading profits.</p>
							<p className="pending price-text mb-0">$99 USDT<span className="text-gray" style={{fontSize:"18px"}}> monthly</span></p>
						</div>
						<div className="data-box mt-3">
							<h5 className="text-gray mb-0">Booster Package</h5>
							<p className="text-gray mb-0">Enjoy more trading profits when subscribing to booster package and start compounding your earnings!</p>
							<p className="pending price-text mb-0">$199 USDT<span className="text-gray" style={{fontSize:"18px"}}> monthly</span></p>
						</div>
					</div>
				</div>

				<div className="row mt-5">
					<div className="col-12 p-1">
						<h5 className="text-gray"><strong>Terms & Condition:</strong></h5>
						<p className="text-gray mb-0">1.</p>
						<p className="text-gray mb-0">2.</p>
						<p className="text-gray mb-0">3.</p>
						<p className="text-gray mb-0">4.</p>
						<p className="text-gray mb-0">5.</p>
					</div>
				</div>
			</div>
		</section>
	);

};

export default Premium;